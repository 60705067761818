import React from 'react';
import { Link } from 'gatsby';

import SalonTooltip from './SalonTooltip';
import TimelyButton from '../../../components/TimelyButton';

const SalonInfoContacts = ({ isButtons, isToMap, classNames, allHours }) => (
	<div className={`salon-info-contacts ${classNames && classNames}`}>
		<div className='salon-info-contacts__row'>
			<h3>Visit the salon</h3>
		</div>
		<div className='salon-info-contacts__row'>
			<SalonTooltip classNames='salon-info__tooltip' allHours={allHours} />
		</div>
		<div className='salon-info-contacts__row'>
			<p className='salon-info__address'>
				<span>Josh Wood Colour</span>
				{isToMap && (
					<a href='#map' className='underline'>
						{' '}
						view on map
					</a>
				)}
				<br />
				<span>6 Lansdowne Mews, London W11 3AN</span>
			</p>
		</div>
		<div
			className={`salon-info-contacts__row 
            salon-info-contacts__row--no-flex`}
		>
			<a
				href='mailto:reservations@joshwoodcolour.com'
				className='underline'
			>
				reservations@joshwoodcolour.com
			</a>
			<a href='tel:+442033930977' className='d-block'>
				+44 20 3393 0977
			</a>
		</div>
		{isButtons ? (
			<div
				className={`salon-info-contacts__row 
                salon-info-contacts__row--buttons`}
			>
				<TimelyButton
					classNames='salon-map__button'
					html={
						'<script>new timelyButton("joshwoodcolour", {"style":"dark"});</script>'
					} // eslint-disable-line
				/>
				<Link
					className='salon-map__button
                    salon-map__experts salon-header-button--grey'
					to='/pages/meet-the-experts'
				>
					<span className='salon-header-button__content'>
						Meet the Team
					</span>
				</Link>
			</div>
		) : null}
	</div>
);
export default SalonInfoContacts;
