import React, { useEffect, useRef } from 'react';
import { isBrowser } from '../../utils/helpers';
import './TrustPilot.scss';

const TrustPilot = ({ id, height }) => {
	const ref = useRef();

	useEffect(() => {
		if (isBrowser && window.Trustpilot) {
			window.Trustpilot.loadFromElement(ref.current, true);
		}
	}, [id]);
	return (
		<div className='trustpilot-widget-wrapper'>
			<div
				ref={ref}
				className='trustpilot-widget'
				data-locale='en-GB'
				data-template-id={id}
				data-businessunit-id='5a8bffcb07bd930001347155'
				data-style-height={height}
				data-style-width='100%'
				data-theme='light'
				data-stars='4,5'
				data-no-reviews='hide'
				data-scroll-to-list='true'
				data-allow-robots='true'
			/>
		</div>
	);
};

export default TrustPilot;
