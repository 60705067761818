// External
import React from 'react';

// Internal
import ProductMiracleButton from './ProductMiracleButton';

const ProductMiracleActions = ({
	product,
	activeRadio,
	activeSecondLevel,
	recommendedProduct,
	isRecommendedSelected,
	setActiveModal,
	canSubscribe,
	selectedValue,
	sellingPlanGroup,
	levelValidator,
	setErrorSelectLevel,
}) => (
	<div className='product-miracle-actions-v2 flex'>
		<ProductMiracleButton
			{...{
				product,
				activeSecondLevel,
				recommendedProduct,
				isRecommendedSelected,
				setActiveModal,
				canSubscribe,
				activeRadio,
				selectedValue,
				sellingPlanGroup,
				levelValidator,
				setErrorSelectLevel,
			}}
			oneTime={activeRadio !== 'auto-delivery'}
		/>
	</div>
);

export default ProductMiracleActions;
