import React, { useContext, useState } from 'react';
import { useStoryblokState } from 'gatsby-source-storyblok';
import { StoreContext } from '../../context/store-context';
import { goToCheckout } from '../../utils/helpers';

const CheckoutButton = ({
	isCartLimitExceeded,
	modalOpen,
	isCartBlocked,
	cartStories,
}) => {
	const story = useStoryblokState(cartStories),
		promoData = story?.content?.upsellModal[0],
		[checkoutModalProduct, setCheckoutModalProduct] = useState(
			promoData?.productHandle
		),
		{
			closeCart,
			cart,
			cartButtonRef,
			upsellModalWasOpened,
			setModal,
			showModal,
		} = useContext(StoreContext),
		showUpsellModal = () => {
			closeCart();
			const info = {
				title: promoData.title,
				text: promoData.text,
				subtext: promoData.subtext,
				image: promoData.image,
			};
			setModal(checkoutModalProduct, 'upsell', '', info);
			showModal(true);
		};

	const checkoutClick = () => {
		const handles = cart.lines.edges.map(
			(item) => item.node.merchandise.product.handle
		);

		const isUpsellProductAdded = handles.includes(checkoutModalProduct);

		upsellModalWasOpened || isUpsellProductAdded
			? goToCheckout(cart, cartButtonRef)
			: showUpsellModal();
	};

	return (
		<button
			disabled={isCartBlocked}
			type='button'
			className='cart-footer__checkout cart__submit'
			onClick={isCartLimitExceeded ? modalOpen : checkoutClick}
		>
			<span>checkout</span>
		</button>
	);
};

export default CheckoutButton;
