import React from 'react';

import './klaviyo-competition.scss';
import SEO from '../../../components/SEO';

const KlaviyoCompetition = () => (
	<>
		<SEO
			title='Competition'
			location={{
				search: null,
				pathname: '/pages/competition',
			}}
		/>
		<section className='klaviyo-competition-container'>
			<div className='klaviyo-form-UKASQq' />
		</section>
	</>
);

export default KlaviyoCompetition;
