// External
import React, { useEffect } from 'react';

const YotpoWidget = ({ product, yotpoRef }) => {
	const productId = product.storefrontId?.replace(
		'gid://shopify/Product/',
		''
	);

	useEffect(() => {
		const loadYotpoScript = () => {
			const appKey = process.env.YOTPO_ID;
			const script = document.createElement('script');
			script.type = 'text/javascript';
			// script.src = `//staticw2.yotpo.com/${appKey}/widget.js`;
			script.src = `https://cdn-widgetsrepository.yotpo.com/v1/loader/${appKey}`;

			script.onload = () => {
				window.yotpo?.initWidgets && window.yotpo?.initWidgets();
			};

			document.head.appendChild(script);

			script.onload = () => {
				if (typeof window?.yotpo !== 'undefined') {
					window.yotpo?.refreshWidgets &&
						window.yotpo?.refreshWidgets();
				} else {
					loadYotpoScript();
				}
			};
		};
		loadYotpoScript();
	}, [product]);

	return (
		<div
			ref={yotpoRef}
			className='yotpo yotpo-main-widget container'			
            data-product-id={productId}
			
            // data-name='{{ product.title | escape }}'
			
            data-url='{{ shop.url }}{{ product.url }}'
			
            data-image-url="{{ product.featured_image | product_img_url: 'large' |replace: '?', '%3F' | replace: '&','%26'}}"
            
            data-price='{{ variant.price | money_without_currency }}'
			// data-currency='{{ shop.currency }}'

			data-description='{{ product.description | escape }}'
		/>
	);
};

export default YotpoWidget;
