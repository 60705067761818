import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

const LocalImage = ({ image, alt = image, classname = '' }) => {
	const { allFile } = useStaticQuery(graphql`
			query MobileImage {
				allFile {
					edges {
						node {
							base
							childImageSharp {
								gatsbyImageData
							}
							relativePath
						}
					}
				}
			}
		`),
		fluid = allFile?.edges?.find(
			({ node }) => node.relativePath === image
		)?.node,
		img = fluid ? getImage(fluid.childImageSharp) : null;

	if (!fluid) {
		return null;
	}
	return img ? (
		<GatsbyImage className={classname} image={img} alt={alt} />
	) : null;
};

export default LocalImage;
