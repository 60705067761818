import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './ProductOneTestimonial.scss';

const ProductOneTestimonial = ({ isMobile = false }) => (
	<div className='testimonial'>
		{isMobile && (
			<div className='testimonial__rating'>
				<span className='testimonial__star'>★★★★★</span>
			</div>
		)}
		<p className='testimonial__quote'>
			“I am always satisfied with the Miracle System, the kit is easy to
			use every time and provides shine and super soft hair plus it covers
			all my white hairs
			<span role='img' aria-label='emoji'>
				😌
			</span>
			. The color is what I need and absolutely fabulous for me. Josh Wood
			never disappoints!! Thank you so much.”
		</p>
		<div className='testimonial__author'>
			{!isMobile && (
				<LazyLoadImage
					src='/images/women-review.png'
					alt='Alexandra N.'
					effect='blur'
				/>
			)}
			<div className='testimonial__details'>
				<span className='testimonial__name bold'>Alexandra N.</span>
				<span className='testimonial__verified'>Verified Buyer</span>
				{!isMobile && (
					<div className='testimonial__rating'>
						<span className='testimonial__star'>★★★★★</span>
					</div>
				)}
			</div>
		</div>
	</div>
);

export default ProductOneTestimonial;
