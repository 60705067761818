import React from 'react';

import ButtonLink from './ButtonLink';
import ButtonMain from './ButtonMain';
import OutStockData from './OutStockData';
import Loader from '../Loader';

import { isBrowser } from '../../utils/helpers';

import './Button.scss';

const Button = (props) => {
	const url = isBrowser ? window.location.href : '',
		buttonTitle = url.includes('utm_source=google')
			? 'buy now'
			: 'add to bag',
		buttonText = props.buttonText ? props.buttonText : buttonTitle,
		type = props.typeButton || 'button',
		buttonClasses = [
			{ 'button--light': props.isLight },
			{ 'button--dark': props.isDark },
			{ 'button--pink': props.isPink },
			{ 'button--half-width': props.isHalfWidth },
			{ 'button--active': !!props.isActiveClass },
			{ 'button--disabled': props.disabled },
			{ 'button--outline-pink': props.isOutlinePink },
		];

	return (
		<>
			{typeof props.availableForSale === 'object' ? (
				<Loader />
			) : (
				<>
					{props.isLink ? (
						<ButtonLink
							{...props}
							buttonClasses={buttonClasses}
							buttonText={buttonText}
							type={type}
						/>
					) : (
						<>
							{props.availableForSale ? (
								<ButtonMain
									{...props}
									buttonClasses={buttonClasses}
									buttonText={buttonText}
									type={type}
								/>
							) : (
								<OutStockData
									isPdp
									product={props.product}
									isLink={props.isLink}
									isPlp={props.isPlp}
									plpVersion={props.plpVersion}
								/>
							)}
						</>
					)}
				</>
			)}
		</>
	);
};

export default Button;
