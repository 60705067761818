import React, { memo, useContext, useState } from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';

import HeaderNav from './HeaderNav';
import HeaderTop from './HeaderTop';
import HeaderLogo from './HeaderLogo';
import MobileDropdown from './MobileDropdown';

import { useWindowWidth } from '../../utils/hooks';
import { StoreContext } from '../../context/store-context';
import { isBrowser } from '../../utils/helpers';
import SearchModal from '../Search/SearchModal';

import './Header.scss';

const Header = ({ scrollY, headerRef, announcement, news, quantity }) => {
	const { openCart, menu } = useContext(StoreContext);
	const windowWidth = useWindowWidth();
	const [isDropdownOpened, setDropdownOpen] = useState(false);
	const [showSearchModal, setShowSearchModal] = useState(false);

	const isMiraclePage = isBrowser && window.location?.pathname === '/miracle';
	const classes = cn('header-section', {
		'header-section--scrolled': scrollY > 70 && !isMiraclePage,
		'header-section--miracle-scrolled': scrollY > 70 && isMiraclePage,
	});

	return (
		<>
			<div className={classes} ref={headerRef}>
				{!isMiraclePage && (
					<HeaderTop
						announcement={announcement}
						windowWidth={windowWidth}
						news={news}
					/>
				)}
				<header className='header'>
					<div className='header__container'>
						<div className='header-nav'>
							<button
								type='button'
								aria-label='header burger'
								className='header__burger'
								onClick={() => setDropdownOpen(true)}
							/>
							<button
								className='search-button'
								aria-label='Header search button'
								onClick={() => setShowSearchModal(true)}
							/>
						</div>
						<nav className='header__middle'>
							<HeaderNav menu={menu?.HeaderCenterMenu} />
							{windowWidth < 992 && <HeaderLogo />}
						</nav>
						<div className='header-nav'>
							{windowWidth > 1300 && (
								<HeaderNav menu={menu?.RightMenu} />
							)}
							<Link
								to='/account'
								role='link'
								className='header__user-icon'
							/>
							<button
								aria-label='open cart'
								className='header__cart-icon'
								onClick={openCart}
								aria-hidden='true'
							>
								<span>{quantity}</span>
							</button>
						</div>
					</div>
					<MobileDropdown
						windowWidth={windowWidth}
						mobileSubMenu={(menu?.MobileMenu || []).find(
							(el) => el.children.length
						)}
						socials={menu?.Socials}
						isDropdownOpened={isDropdownOpened}
						setDropdownOpen={setDropdownOpen}
					/>
				</header>
			</div>
			<div
				className={cn('overlay overlay--burger', {
					'overlay--active': isDropdownOpened,
				})}
				role='button'
				aria-label='overlay'
				onClick={() => setDropdownOpen(false)}
				onKeyDown={() => setDropdownOpen(false)}
				tabIndex='0'
			/>
			{showSearchModal && (
				<SearchModal closeModal={() => setShowSearchModal(false)} />
			)}
		</>
	);
};

export default memo(Header);
