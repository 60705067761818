import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';

import Spinner from '../Spinner';

import { StoreContext } from '../../context/store-context';
import { useWindowWidth } from '../../utils/hooks';
import { getMetafield } from '../../utils/helpers';

const ButtonMain = (props) => {
	const windowWidth = useWindowWidth();
	const [activeId, setActiveId] = useState(null);
	const {
		addVariantToCart,
		loading,
		addMultiVariantsToCart,
		openShotModal,
		closeShotModal,
		setBundleProducts,
		setShowBundle,
		setNotificationText,
		setModal,
		showModal,
		replaceCartItem,
		cart,
	} = useContext(StoreContext);
	const id = props.variantId
		? props.variantId
		: props.product && props.product?.variants[0].storefrontId;

	useEffect(() => {
		!loading && setActiveId(null);
	}, [loading]);

	const isPermanentColour = getMetafield(
		props.product,
		'is_permanent_colour'
	);
	const chooseVariantText = getMetafield(
		props.product,
		'choose_variant_text'
	);

	const handleAddToCart = () => {
		const isRecommended =
			props.recommendedProduct && props.isRecommendedSelected;
		const multiplyItems = isRecommended && [
			{
				variantId: props.variantId
					? props.variantId
					: props.product.variants[0].storefrontId,
				quantity: 1,
			},
			{
				variantId: props.recommendedProduct.storefrontId,
				quantity: 1,
			},
		];

		if (props.isBundlePdp && props.disabled) {
			const text = (props.product?.options || []).some((i) =>
				i.values.some((v) => v.includes('cm'))
			)
				? 'Please choose your size'
				: chooseVariantText || 'Please choose a shade shot';
			setModal(null, 'link', text);
			showModal(true);
		}

		if (!props.disabled && !props.isBundle) {
			setActiveId(id);

			isRecommended
				? addMultiVariantsToCart(multiplyItems)
				: addVariantToCart(id, 1, [], false, props.product);

			closeShotModal();
			windowWidth < 768 && setShowBundle(false);
			setNotificationText(
				`${props.product.title} ${
					props.hasOwnProperty('selectedOption')
						? props.selectedOption.label
						: ''
				}`
			);
		}
		if (props.isBundle) {
			setBundleProducts(props.product);
			openShotModal();
		}
	};

	const handleAddToCartWithUpsell = () => {
		localStorage.setItem(
			'modalPermanentData',
			JSON.stringify({
				allBaseColours: props.allBaseColours,
				product: props.product,
			})
		);
		const lineItems = cart.lines.edges,
			upsellItem =
				lineItems.length &&
				lineItems.find(
					(item) =>
						Object.keys(item.node.attributes).length > 0 &&
						item.node.attributes.some(
							(attr) => attr['key'] === 'isUpsellCart'
						)
				),
			baseId =
				props.product.variants &&
				props.product.variants[0].storefrontId,
			customAttrs = [
				{
					key: 'isUpsellCart',
					value: 'true',
				},
				{
					key: 'baseColourId',
					value: baseId || 'null',
				},
				{
					key: 'variantId',
					value: 'null',
				},
				{
					key: 'shotId',
					value: 'null',
				},
			];

		if (baseId) {
			upsellItem && baseId
				? replaceCartItem(
						cart.id,
						upsellItem.node.merchandise.id,
						upsellItem.node.id,
						baseId,
						customAttrs
				  )
				: addVariantToCart(baseId, 1, customAttrs);
		}
	};

	const handleCLick = () => {
		isPermanentColour && props.isPdp
			? handleAddToCartWithUpsell()
			: handleAddToCart();
	};

	return (
		<button
			type={props.type}
			className={cn(
				`button${props.classname ? ` ${props.classname}` : ''}`,
				props.buttonClasses
			)}
			data-test-id='button-main__add--bag'
			onClick={handleCLick}
		>
			{activeId !== id ? (
				<span className='button__text'>{props.buttonText}</span>
			) : (
				<Spinner />
			)}
		</button>
	);
};

export default ButtonMain;
