/* eslint-disable */
import React, { useEffect, useState } from 'react';

import FrequentlyProductImage from './FrequentlyProductImage';
import Yotpo from '../../../../components/Yotpo';

import {
	createArray,
	decodeBase64,
	getMetafield,
} from '../../../../utils/helpers';
import useProductByHandle from '../../../../utils/useProductByHandle';

const ProductsItem = ({
	isMain,
	handle,
	setSelectedProductsIds,
	selectedProductsIds,
	setProductData,
}) => {
	const [variants, setVariants] = useState([]),
		[selectedVariant, setSelectedVariant] = useState(null),
		[isChecked, setChecked] = useState(true),
		product = useProductByHandle(handle),
		advantages = product ? createArray(getMetafield(product, 'list')) : [];

	useEffect(() => {
		if (product?.variants) {
			setVariants(product.variants);
		}
	}, [product]);

	useEffect(() => {
		if (variants) {
			setSelectedVariant(variants[0]);
		}
	}, [variants]);

	useEffect(() => {
		if (selectedVariant && product) {
			setSelectedProductsIds([
				...selectedProductsIds,
				{
					productId: product.storefrontId,
					variantId: selectedVariant.storefrontId,
					variantPrice: parseInt(selectedVariant.price).toFixed(0),
				},
			]);
		}
	}, [selectedVariant, product]);

	const handleSelect = (id) => {
			if (product) {
				setSelectedProductsIds(
					selectedProductsIds.filter(
						(item) => item.productId !== product.storefrontId
					)
				);
				setSelectedVariant(
					variants.filter((item) => item.title === id)[0]
				);
			}
		},
		handleCheck = () => {
			setChecked(!isChecked);
			if (isChecked) {
				setSelectedProductsIds(
					selectedProductsIds.filter(
						(item) => item.productId !== product.storefrontId
					)
				);
			} else {
				setSelectedProductsIds([
					...selectedProductsIds,
					{
						productId: product.storefrontId,
						variantId: selectedVariant.storefrontId,
						variantPrice: parseInt(selectedVariant.price).toFixed(
							0
						),
					},
				]);
			}
		},
		openProductModal = () => {
			if (product && selectedVariant) {
				setProductData({
					id: decodeBase64(product.storefrontId),
					productType: product.productType,
					title: product.title,
					handle: product.handle,
					description: product.descriptionHtml,
					advantages,
					price: parseInt(
						product.priceRangeV2.minVariantPrice.amount
					).toFixed(0),
					image:
						product.images[0].localFile &&
						product.images[0].localFile.childImageSharp
							? product.images[0].localFile.childImageSharp
									.gatsbyImageData // eslint-disable-line
							: product.images[0].originalSrc,
				});
			}
		};

	useEffect(() => {
		if (selectedProductsIds && selectedVariant) {
			setChecked(
				selectedProductsIds.some(
					(item) => item.variantId === selectedVariant.storefrontId
				)
			);
		}
	}, [selectedProductsIds, selectedVariant]);

	return (
		<div className='frequently-product'>
			<FrequentlyProductImage
				handleCheck={handleCheck}
				openProductModal={openProductModal}
				product={product}
				selectedVariant={selectedVariant}
				selectedProductsIds={selectedProductsIds}
				isChecked={isChecked}
			/>
			<div
				className={`frequently-product__content ${
					!isChecked && 'frequently-product--disabled'
				}`}
			>
				<div
					className={`frequently-product__label ${
						isMain && 'frequently-product__label--main'
					}`}
				>
					{isMain ? 'This Item' : 'Recommended'}
				</div>
				{product && <h3>{product.title}</h3>}
				<div className='frequently-product__row'>
					{selectedVariant && selectedVariant.price ? (
						/* eslint-disable-next-line */
						<div className='frequently-product__price'>
							£ {parseInt(selectedVariant.price).toFixed(0)}
						</div>
					) : null}
					{product && product.rating ? (
						<div className='frequently-product__rate'>
							<Yotpo rating={product.rating} />
						</div>
					) : null}
				</div>
				{variants && variants.length > 1 ? (
					<form className='frequently-product__select'>
						<select onChange={(e) => handleSelect(e.target.value)}>
							{variants.map((item) => (
								<option
									key={item.storefrontId}
									value={item.storefrontId}
								>
									{item.title}
								</option>
							))}
						</select>
					</form>
				) : null}
			</div>
		</div>
	);
};

export default ProductsItem;
