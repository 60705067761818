import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './MiracleInfo.scss';

const miracleInfo = [
	{
		image: 'miracle-system-product.png',
		title: 'Get salon-quality colour at home',
		subtitle: 'No more waiting for an appointment…',
		desc: [
			'Want to take control of your greys? Our rich, multi-toned colour gives 100% grey coverage that is natural looking and conditions whilst you colour.',
			'The result? Confidence, compliments, and incredible colour - we did warn you.',
		],
	},
	{
		image: 'colour-about-u.png',
		title: "Colour that's all about you",
		subtitle: "Because you're one of a kind…",
		desc: [
			'Want hair that’s truly you? Our toning Shade Shot is your answer.',
			'It creates a personalised tone to match your skin and eyes perfectly. Goodbye block colour, hello confidence!',
		],
	},
	{
		image: 'miracle-system-product.png',
		title: 'Hair condition that gets better with time',
		subtitle: "Who said colouring can't be good for you?",
		desc: [
			'Remember when hair colouring meant damage? Not anymore.',
			'The Miracle System is packed with nourishing vitamins that make your hair stronger each time you use it.',
			'Less breakage, more shine, and hair that makes you feel ready for anything.',
		],
	},
];

const MiracleInfo = () => (
	<div className='pdp-miracle-info container'>
		<div className='pdp-miracle-info__items'>
			{miracleInfo.map((item) => (
				<div key={item.title} className='pdp-miracle-info__item'>
					<div className='pdp-miracle-info__item__image'>
						<LazyLoadImage
							src={`/images/pdp/${item.image}`}
							alt={item.title}
							effect='blur'
						/>
					</div>
					<div className='pdp-miracle-info__item__text'>
						<h4>{item.title}</h4>
						<p>
							<b>{item.subtitle}</b>
						</p>
						{item.desc.map((desc) => (
							<p key={desc}>{desc}</p>
						))}
					</div>
				</div>
			))}
		</div>
	</div>
);

export default MiracleInfo;
