import React, { useContext, useEffect } from 'react';

import CloseIcon from '../CloseIcon';
import CartItem from './CartItem';
import CartFooter from './CartFooter';
import Loader from '../Loader';
import { StoreContext } from '../../context/store-context';

const CartBody = ({
	hasItems,
	cart,
	quantity,
	closeCart,
	isOpen,
	cartLimit,
	setModal,
	showModal,
	setSelectedPermanentBase,
	selectedPermanentBase,
	setSelectedPermanentShot,
	selectedPermanentShot,
	productsList,
	selectedValue,
	setSelectedValue,
	cartStories,
}) => {
	const { isCartBlocked } = useContext(StoreContext),
		lineItems = hasItems ? cart.lines.edges : [],
		isCartLimitExceeded =
			cartLimit &&
			lineItems &&
			lineItems.some((item) => item.node.quantity > cartLimit),
		modalOpen = () => {
			showModal(true);
			setModal(
				null,
				'cart-limit',
				'Cannot place order, conditions not met'
			);
		};

	useEffect(() => {
		if (isOpen && cartLimit) {
			isCartLimitExceeded && modalOpen();
		}
	}, [lineItems]);

	return (
		<>
			{hasItems && (
				<>
					<div className='cart-header'>
						<span className='cart-header__title'>Your bag </span>
						{!isCartBlocked && (
							<span className='cart-header__count'>
								{quantity} {quantity > 1 ? ' items' : ' item'}
							</span>
						)}
						<CloseIcon
							handleClose={closeCart}
							classname='cart-close'
						/>
					</div>
					<div className='cart-items'>
						{isCartBlocked ? (
							<Loader />
						) : (
							<ul>
								{lineItems.map((item) => (
									<CartItem
										key={`cartItem${item.node.id}`}
										item={item}
										cartLimit={cartLimit}
										lineItems={lineItems}
										setSelectedPermanentBase={
											setSelectedPermanentBase
										}
										selectedPermanentBase={
											selectedPermanentBase
										}
										setSelectedPermanentShot={
											setSelectedPermanentShot
										}
										selectedPermanentShot={
											selectedPermanentShot
										}
										productsList={productsList}
										selectedValue={selectedValue}
										setSelectedValue={setSelectedValue}
									/>
								))}
							</ul>
						)}
					</div>
				</>
			)}
			<CartFooter
				hasItems={hasItems}
				isOpen={isOpen}
				closeCart={closeCart}
				cart={cart}
				productsList={productsList}
				isCartLimitExceeded={isCartLimitExceeded}
				isCartBlocked={isCartBlocked}
				modalOpen={modalOpen}
				cartStories={cartStories}
			/>
		</>
	);
};

export default CartBody;
