import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { v4 as uuid } from 'uuid';

import Button from '../../../components/Button';

import {
	decodeBase64,
	getQuery,
	isBrowser,
	capitalize,
} from '../../../utils/helpers';
import {
	setHrefLangTag,
	removeHrefLangTag,
} from '../../../helpers/setHrefLang';

import './ThankYou.scss';

export const query = graphql`
	{
		allProducts: allShopifyProduct {
			nodes {
				...ProductCard
			}
		}
	}
`;

const ThankYou = () => {
	const {
		allProducts: { nodes: allProducts },
	} = useStaticQuery(query);
	const [currentProducts, setCurrentProducts] = useState([]);

	const order = getQuery('conversionref');
	const tax = getQuery('tax');
	const currency = getQuery('currency');
	const coupon = getQuery('voucher');
	const total = getQuery('total');
	const subtotal = getQuery('subtotal');
	const shipping = getQuery('shipping');
	const customerId = getQuery('customer_id');

	const customerEmail =
		!!getQuery('customer_email') &&
		decodeBase64(getQuery('customer_email'));
	const firstName =
		!!getQuery('first_name') &&
		capitalize(decodeBase64(getQuery('first_name')));
	const lastName =
		!!getQuery('last_name') &&
		capitalize(decodeBase64(getQuery('last_name')));
	const purchasedItems =
		!!getQuery('items') &&
		JSON.parse(decodeURIComponent(getQuery('items')));

	useEffect(() => {
		const products = allProducts.filter((product) =>
			product.variants.some((variant) =>
				(purchasedItems || []).some(
					(item) =>
						item.id === parseInt(decodeBase64(variant.storefrontId))
				)
			)
		);
		setCurrentProducts(products);

		setHrefLangTag('thank-you-hreflang');

		return () => {
			removeHrefLangTag('thank-you-hreflang');
		};
	}, []);

	useEffect(() => {
		if (isBrowser && currentProducts.length) {
			const products = (currentProducts || []).map((product) => {
				const currentVariant = (product.variants || []).find(
					(variant) =>
						purchasedItems.some(
							(item) =>
								item.id ===
								parseInt(decodeBase64(variant.storefrontId))
						)
				);
				const currentVariantId = decodeBase64(
					currentVariant.storefrontId
				);
				const currentItem = purchasedItems.find(
					(item) => item.id === parseInt(currentVariantId)
				);

				return {
					name: product?.title,
					id: currentVariant.sku,
					product_id: decodeBase64(product.storefrontId),
					variant_id: currentVariantId,
					price: currentVariant.price,
					quantity: currentItem.qty,
				};
			});

			window['dataLayer'].push({ ecommerce: null });
			window['dataLayer'].push({
				event: 'dl_subscription_purchase',
				event_id: uuid(),
				user_properties: {
					customer_id: customerId,
					customer_email: customerEmail,
					customer_first_name: firstName,
					customer_last_name: lastName,
				},
				ecommerce: {
					currencyCode: currency,
					purchase: {
						actionField: {
							id: order,
							affiliation: 'Bold Subscription',
							revenue: total,
							subtotal,
							tax,
							shipping,
							coupon,
						},
						products,
					},
				},
			});
		}
	}, [currentProducts.length]);

	return (
		<div className='thank-you-page'>
			<div className='thank-you-page__container'>
				<div className='thank-you-page__left'>
					<div className='thank-you-page__left-container'>
						<h1 className='thank-you-page__title'>
							Thank {firstName} {lastName} for your order!
						</h1>

						<div className='thank-you-page__buttons'>
							<Button
								isLink
								isDark
								buttonText='Continue Shopping'
								linkUrl='/collections/all-products'
							/>
							<button
								onClick={isBrowser ? window.print : null}
								className='thank-you-page__link'
							>
								Print receipt
							</button>
						</div>
					</div>
				</div>
				<div className='thank-you-page__cart thank-you-cart'>
					<p className='thank-you-cart__title'>
						<span className='thank-you-page__text'>
							Order summary
						</span>

						<span className='thank-you-page__order-id'>
							ID {order}
						</span>
					</p>
					{(currentProducts || []).map((product, i) => {
						const currentVariant = product.variants.find(
							(variant) =>
								purchasedItems.some(
									(item) =>
										item.id ===
										parseInt(
											decodeBase64(variant.storefrontId)
										)
								)
						);
						const currentItem = purchasedItems.find(
							(item) =>
								item.id ===
								parseInt(
									decodeBase64(currentVariant.storefrontId)
								)
						);

						return (
							<div
								key={currentVariant?.storefrontId}
								className='thank-you-product'
							>
								<div className='thank-you-product__img'>
									{currentVariant?.image ? (
										<GatsbyImage
											loading='eager'
											alt={currentVariant?.title}
											image={getImage(
												currentVariant.image
													?.gatsbyImageData
											)}
										/>
									) : (product?.images || []).length ? (
										<GatsbyImage
											loading='eager'
											alt={currentVariant?.title}
											image={getImage(
												product.images[0]
													?.gatsbyImageData
											)}
										/>
									) : null}
								</div>
								<div className='thank-you-product__right'>
									<p className='thank-you-product__name'>
										{currentVariant?.title}
									</p>
									<div className='thank-you-product__bottom-text'>
										<span className='thank-you-product__period'>
											Every {currentItem.frequency}
										</span>
										<span className='thank-you-product__price'>
											£{+currentVariant.price}
										</span>
									</div>
								</div>
							</div>
						);
					})}

					<div className='thank-you-page__all-prices'>
						<div className='thank-you-page__price'>
							<span>Subtotal</span>
							<span>£{subtotal || 0}</span>
						</div>
						<div className='thank-you-page__price'>
							<span>Shipping</span>
							<span>£{shipping || 0}</span>
						</div>
						<div className='thank-you-page__price'>
							<span>Taxes</span>
							<span>£{parseInt(tax) || 0}</span>
						</div>

						<div className='thank-you-page__price thank-you-page__price--total'>
							<span>Total</span>
							<span>£{total || 0}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ThankYou;
