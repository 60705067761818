// External
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Internal
import cn from 'classnames';
import LocalImage from '../../../../../components/LocalImage';
import { getMetafield } from '../../../../../utils/helpers';

const LevelListItem = ({
	isFilterActive,
	nextStep,
	activeStep,
	isSecondLevel = false,
	item,
	setActiveLevel,
	activeLevel,
	activeType,
	filtersList,
	isOneLevel,
}) => {
	getMetafield(item, 'selection_product_image');

	const filterTypes =
		getMetafield(item, 'filter_type') &&
		getMetafield(item, 'filter_type').split('|');

	const title = isOneLevel
		? item.title
		: item.title && item.title.split('-')[0];

	const firstLevelImage = !isSecondLevel
		? getMetafield(item, 'selection_product_image')
		: getMetafield(item, 'variant_image');
	const image = !isOneLevel
		? firstLevelImage
		: getMetafield(item, 'variant_image');
	
	const variantDescription = getMetafield(item, 'description') || '';

	const classes = cn(
		'pdp-selection-level-row-v2__img',
		{
			'pdp-selection-level-row-v2__img--active':
				activeLevel && activeLevel.title === item.title,
		}
	);

	const handleClick = (item, description) => {
		setActiveLevel(item, description);
		nextStep && nextStep();
	};

	return (
		<div
			className={classes}
			onClick={() => handleClick(item, variantDescription)}
		>
			{image ? (
				<LazyLoadImage
					src={image}
					alt={item.title}
					effect='blur'
					delayTime={0}
				/>
			) : (
				<span>{title}</span>
			)}
		</div>
	);
};

export default LevelListItem;
