import React, { useEffect } from 'react';
import { useInstantSearch, useSearchBox } from 'react-instantsearch';
import { getMetafield, getQuery } from '../../utils/helpers';
import ProductsListGrid from '../collections/ProductsListGrid';
import Loader from '../../components/Loader';

const Search = ({ allProducts, featuredProducts }) => {
	const searchParameter = decodeURIComponent(getQuery('filter') ?? '');

	const { results, status } = useInstantSearch();
	const { hits, query } = results;
	const { refine } = useSearchBox();

	const productsHandles = hits?.map((product) => product.handle);
	const productsToShow = allProducts
		.filter((product) => {
			if (!productsHandles?.includes(product.handle)) return false;
			const isHideProductInSearch = getMetafield(
				product,
				'hide_product_from_search_results'
			);
			if (isHideProductInSearch) return isHideProductInSearch === 'false';
			return true;
		})
		?.sort(
			(product1, product2) =>
				productsHandles.indexOf(product1.handle) -
				productsHandles.indexOf(product2.handle)
		);

	const resultText = searchParameter
		? productsToShow.length === 1
			? `1 Result for "${searchParameter ?? ''}"`
			: `${productsToShow?.length} Results for "${searchParameter ?? ''}"`
		: `0 Results for ""`;

	useEffect(() => {
		if (searchParameter && query !== searchParameter) {
			refine(searchParameter);
		}
	}, [searchParameter]);

	if (
		status === 'stalled' ||
		(searchParameter !== '' && query !== searchParameter)
	) {
		return <Loader isFullScreen />;
	}

	return (
		<div className='search-page-container'>
			<h2>{resultText}</h2>
			{(!searchParameter || productsToShow?.length === 0) && (
				<p>Check the spelling or try a more general term.</p>
			)}
			{searchParameter && productsToShow?.length > 0 && (
				<ProductsListGrid productsList={productsToShow} />
			)}
			{(!searchParameter || productsToShow?.length === 0) && (
				<>
					<h2>Featured products</h2>
					<ProductsListGrid productsList={featuredProducts} />
				</>
			)}
		</div>
	);
};

export default Search;
