import React, { useContext } from 'react';
import cn from 'classnames';

import CloseIcon from '../CloseIcon';
import ModalRed from './ModalRed';
import ModalShadeShot from './ModalShadeShot';
import ModalLinks from './ModalLinks';
import ModalKlaviyo from './ModalKlaviyo';
import ContactForm from '../ContactForm';
import ModalUpsell from './ModalUpsell';
import ModalRecommendedVideo from './ModalRecommendedVideo';
import ModalCartLimit from './ModalCartLimit';
import ModalLongTap from './ModalLongTap';
import ModalAutoDelivery from './ModalAutoDelivery';
import ModalExpert from './ModalExpert';
import ModalTooltip from './ModalTooltip';
import ModalNotColour from './ModalNotColour';
import ModalCustomerInfo from './ModalCustomerInfo';
import MiracleModal from './MiracleModal';
import ModalPermanentDesktop from './ModalPermanentDesktop';
import ModalPermanentMobile from './ModalPermanentMobile';

import { StoreContext } from '../../context/store-context';

import './Modal.scss';
import ModalStepsFirst from './ModalStepsFirst';
import { goToCheckout } from '../../utils/helpers';
import ModalZoomImages from './ModalZoomImages';

const Modal = () => {
	const {
		modal,
		activeModal,
		showModal,
		setCurrentStepPDP,
		currentStepPDP,
		cart,
		setAddShadeShotFromCart,
	} = useContext(StoreContext);

	const handleClose = () => {
		modal && showModal(false);
		setCurrentStepPDP(1);
		setAddShadeShotFromCart(false);
	};

	const modalClasses =
		modal &&
		cn(
			{ 'modal-video': modal.type === 'recommended-video' },
			{ 'modal--active': activeModal },
			{ 'modal--full': modal.type === 'contact us' },
			{ 'modal--upsell': modal.type === 'upsell' },
			{ 'modal--delivery': modal.type === 'modal-steps' },
			{ 'modal-steps-first': modal.type === 'steps-first' },
			{
				'modal--edit-customer-info':
					modal.type === 'edit-customer-info',
			},
			{ 'modal--miracle-pdp': modal.type === 'miracle-pdp' },
			{
				'modal--permanent-selection':
					modal.type === 'permanent-selection' ||
					modal.type === 'permanent-selection-from-cart',
			},
			{
				'modal--full-screen':
					modal.type === 'permanent-selection-mobile' ||
					modal.type === 'zoom-images-mobile',
			},
			{
				'no-border':
					modal.type === 'permanent-selection-mobile' ||
					modal.type === 'zoom-images-mobile',
			}
		);

	return (
		<div className={`modal ${modalClasses}`}>
			{modal &&
				modal.type !== 'long-tap' &&
				modal.type !== 'permanent-selection-mobile' && (
					<CloseIcon
						handleClose={
							modal.type === 'upsell'
								? () => goToCheckout(cart)
								: handleClose
						}
						classname='modal-close'
					/>
				)}
			<div
				className={`modal__container ${
					modal && `modal__container--${modal.type}`
				}`}
			>
				{modal && modal.type === 'red' && <ModalRed modal={modal} />}
				{modal && modal.type === 'shadeshot' && (
					<ModalShadeShot modal={modal} showVideo={activeModal} />
				)}
				{modal &&
					(modal.type === 'link' || modal.type === 'ingredients') && (
						<ModalLinks modal={modal} />
					)}
				{modal && modal.type === 'steps-first' && (
					<ModalStepsFirst modal={modal} />
				)}
				{modal && modal.type === 'klaviyo' && (
					<ModalKlaviyo modal={modal} handleClose={handleClose} />
				)}
				{/* {modal && modal.type === 'contact us' && <ContactForm />} */}
				{modal && modal.type === 'upsell' && (
					<ModalUpsell modal={modal} />
				)}
				{modal && modal.type === 'recommended-video' && (
					<ModalRecommendedVideo link={modal.text} />
				)}
				{modal && modal.type === 'cart-limit' && (
					<ModalCartLimit handleClose={handleClose} />
				)}
				{modal && modal.type === 'long-tap' && (
					<ModalLongTap modal={modal} />
				)}
				{modal && modal.type === 'modal-steps' && (
					<ModalAutoDelivery modal={modal} />
				)}
				{modal && modal.type === 'expert' && (
					<ModalExpert modal={modal} />
				)}
				{modal && modal.type === 'tooltip' && (
					<ModalTooltip modal={modal} />
				)}
				{modal && modal.type === 'not-colour' && (
					<ModalNotColour modal={modal} />
				)}
				{modal && modal.type === 'edit-customer-info' && (
					<ModalCustomerInfo modal={modal} />
				)}
				{modal && modal.type === 'miracle-pdp' && (
					<MiracleModal modal={modal} />
				)}
				{modal &&
					(modal.type === 'permanent-selection' ||
						modal.type === 'permanent-selection-from-cart') && (
						<ModalPermanentDesktop
							modal={modal}
							currentStep={currentStepPDP}
							setCurrentStep={setCurrentStepPDP}
							activeModal={activeModal}
							handleClose={handleClose}
						/>
					)}
				{modal && modal.type === 'permanent-selection-mobile' && (
					<ModalPermanentMobile modal={modal} />
				)}

				{modal && modal.type === 'zoom-images-mobile' && (
					<ModalZoomImages modal={modal} />
				)}
			</div>
		</div>
	);
};

export default Modal;
