// External
import React, { useContext, useEffect, Suspense } from 'react';
import { graphql } from 'gatsby';
import { StoryblokComponent } from 'gatsby-source-storyblok';

// Internal
import SEO from '../components/SEO';
import Homepage from '../templates/Homepage';
import { StoreContext } from '../context/store-context';
import { isBrowser } from '../utils/helpers';
import applyDiscount from '../utils/cart/applyDiscount';
import usePreviewableStoryblokState from '../utils/usePreviewableStoryblokState';
import { codesToPreAdd, seo } from '../constants/home-page';
import { applyPreAddDiscount } from '../helpers/homepage';
import Loader from '../components/Loader';

const IndexPage = ({ data, location, code }) => {
	const { cart, addVariantToCart, sbClient } = useContext(StoreContext);
	const page = seo['/'];
	const { story } = usePreviewableStoryblokState(
		sbClient,
		data.storyblokEntry
	);

	const components = story.content.body.map((blok) => (
		<Suspense fallback={<Loader />} key={blok._uid}>
			<StoryblokComponent blok={blok} />
		</Suspense>
	));
	useEffect(() => {
		const handleDiscount = async () => {
			const cartId = isBrowser && localStorage.getItem('shopify_cart_id');
			const matchingDiscountCode = codesToPreAdd.find(
				(item) => item.code === code
			);

			if (matchingDiscountCode) {
				await applyPreAddDiscount(cartId, code, addVariantToCart);
			} else {
				await applyDiscount(cartId, code);
			}

			if (isBrowser) {
				window.history.pushState(null, null, '/');
				window.location.reload();
			}
		};
		if (code && cart) {
			handleDiscount();
		}
	}, [code, cart]);
	return (
		<>
			<SEO page={page} location={location} />
			<Homepage components={components} />
		</>
	);
};

export const query = graphql`
	{
		allShopifyProduct(
			filter: {
				handle: {
					in: [
						"miracle-system"
						"dark-brown-blending-brush"
						"dark-blonde-blending-brush"
					]
				}
			}
		) {
			nodes {
				...ProductCard
			}
		}
		storyblokEntry(full_slug: { eq: "home" }) {
			content
			name
			full_slug
			uuid
			id
			internalId
		}
	}
`;

export default IndexPage;
