import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { decodeBase64 } from '../../../utils/helpers';
import {
	submitErrorToSlack,
} from '../../../utils/slackMessage';

const ModalKlaviyo = ({ modal, handleClose }) => {
	const [inputVal, setInputVal] = useState(''),
		[varId, setVarId] = useState(null),
		[requestStatus, setRequestStatus] = useState(''),
		[isMessageVisible, setMessageVisible] = useState(false),
		[errorMessage, setErrorMessage] = useState('');

	useEffect(() => {
		// Decoded variantID from base64
		const storefrontId = modal.product.variants[0].storefrontId;
		const id = storefrontId.includes('gid://shopify')
			? storefrontId.replace('gid://shopify/ProductVariant/', '')
			: decodeBase64(storefrontId);
		setVarId(id);
		// eslint-disable-next-line  react-hooks/exhaustive-deps
	}, []);
	const closeModalKlavio = () => {
		setMessageVisible(false);
		setInputVal('');
		handleClose();
	},
		notifyHandle = async () => {
			const formdata = new FormData();
			formdata.append('a', `${process.env.KLAVIYO_ID}`);
			formdata.append('email', `${inputVal}`);
			formdata.append('variant', `${varId}`);
			formdata.append('platform', 'shopify');

			const url = 'https://a.klaviyo.com/onsite/components/back-in-stock/subscribe'.replace(/\s+/g, '');
			try {
				const response = await axios.post(
					url,
					formdata,
					{ redirect: 'follow' }
				);

				if (!response.data) {
					throw new Error('API request failed');
				}

				const result = response.data;
				setMessageVisible(true);

				if (result.success) {
					setRequestStatus('success');
				} else if (result.message) {
					if (result.message.includes('Enter a valid email address.')) {
						setRequestStatus('error');
						setErrorMessage('Enter a valid email address.');
					}
					if (result.message.includes('This field is required.')) {
						setRequestStatus('error');
						setErrorMessage('This field is required.');
					}
				}
			} catch (error) {
				setMessageVisible(true);
				setRequestStatus('error');
				setErrorMessage('Something was wrong.');
				const status = error?.response?.status;
				if (status >= 500) {
					submitErrorToSlack(url, error, 'POST');
				}
				console.error(error);
				return error;
			}
		};

	return (
		<div className='modal-klaviyo'>
			<h3 className='modal-klaviyo__title'>{modal.product.title}</h3>
			<p className='modal-klaviyo__text'>
				Register to receive a notification when this item comes back in
				stock.
			</p>
			<input
				type='text'
				name='email'
				placeholder='Email'
				className='modal-klaviyo__input'
				value={inputVal}
				onChange={(e) => setInputVal(e.target.value)}
			/>
			{isMessageVisible ? (
				<>
						<div
							className={`modal-klaviyo__message 
                            modal-klaviyo__message--success`}
						>
							You're in! We'll let you know when it's back.
						</div>
					{/* {requestStatus === 'success' ? (
					) : (
						<div
							className={`modal-klaviyo__message 
                            modal-klaviyo__message--error`}
						>
							{errorMessage}
						</div>
					)} */}
				</>
			) : null}
			{requestStatus !== 'success' ? (
				<button
					className='modal-klaviyo__button button button--dark'
					onClick={notifyHandle}
				>
					<span>notify me when available</span>
				</button>
			) : null}
		</div>
	);
};

export default ModalKlaviyo;
