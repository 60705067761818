import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { StoreContext } from '../../../context/store-context';

import ProductPairWith from './ProductPairWith';

import {
	createArray,
	decodeBase64,
	getMetafield,
} from '../../../utils/helpers';

const ProductPairWithContainer = ({ product, cart }) => {
	const pairProducts = createArray(getMetafield(product, 'pair_with')),
		{ yotpoRatings } = useContext(StoreContext),
		{
			allShopifyProduct: { edges },
		} = useStaticQuery(
			graphql`
				{
					allShopifyProduct(sort: { createdAt: DESC }) {
						edges {
							node {
								...ProductCard
							}
						}
					}
				}
			`
		),
		products = edges.filter((e) => pairProducts.includes(e.node.handle));

	(products || []).map((item, index) => {
		(yotpoRatings || []).find((rating) => {
			if (rating.domain_key === decodeBase64(item.node.storefrontId)) {
				products[index].node.rating = rating;
			}
		});
	});

	return products.length ? (
		<ProductPairWith products={products} cart={cart} />
	) : null;
};

export default ProductPairWithContainer;
