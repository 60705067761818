/* eslint-disable */
import React, { useContext } from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';

import { StoreContext } from '../../context/store-context';
import HeaderLogo from './HeaderLogo';

const HeaderNav = ({ menu, collections }) => {
	const { isOpenSubmenu, openMenu, closeMenu } = useContext(StoreContext);

	return (
		<ul className='site-nav'>
			{(menu || []).map(({ id, children, url, title }) => {
				const classes = cn('site-nav__item', {
					'site-nav__item--has-dropdown': !!children && isOpenSubmenu,
				});
				return (
					<div key={id}>
						{title === 'header-logo' ? (
							<HeaderLogo />
						) : (
							<li className={classes}>
								<div
									onClick={children ? closeMenu : null}
									onMouseEnter={children ? openMenu : null}
									onMouseLeave={children ? closeMenu : null}
								>
									<Link
										className='site-nav__link'
										activeClassName='active'
										to={url}
									>
										<span className='site-nav__text'>
											{title}
										</span>
									</Link>
								</div>
							</li>
						)}
					</div>
				);
			})}
		</ul>
	);
};

export default HeaderNav;
