import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useWindowWidth } from '../../../utils/hooks';
import ProductOneTestimonial from '../ProductFirstScreenV2/ProductOneTestimonial';

import './ProductTestimonial.scss';

const aboutMiracle = [
	{
		title: 'Salon-quality colour at home:',
		description:
			'Achieve rich, professional-looking colour in the comfort and convenience of your home.',
	},
	{
		title: 'Hair-loving ingredients:',
		description:
			'Packed with Vitamins B3, B5, and Omega 3 & 6, our gentle, ammonia-free formula is clinically proven to protect as it colours.',
	},
	{
		title: 'Confidence from root to tip:',
		description:
			'Get 100% grey coverage and stronger, healthier hair every time you colour.',
	},
];

const ProductTestimonial = () => {
	const windowWidth = useWindowWidth();
	return (
		<div className='container'>
			<div className='pdp-testimonial'>
				<h2 className='pdp-testimonial__title'>
					The Miracle System covers and colours greys{' '}
					{windowWidth > 750 && <br />}while nourishing and shielding
					your hair
				</h2>

				<p className='pdp-testimonial__desc'>
					Get ready to fall back in love with your hair colour
				</p>

				<div className='pdp-testimonial__content'>
					<div className='pdp-testimonial__content__image'>
						<LazyLoadImage
							src='/images/home-page/image1.webp'
							alt='Testimonial about the Miracle System'
							effect='blur'
						/>
					</div>
					<div className='pdp-testimonial__content__text'>
						{aboutMiracle.map((item) => (
							<div
								key={item.title}
								className='pdp-testimonial__content__text__item'
							>
								<div className='pdp-testimonial__content__text__list' />
								<p>
									<b>{item.title}</b> {item.description}
								</p>
							</div>
						))}
						<Link
							to='/miracle-steps'
							className='pdp-testimonial__content__text__link'
						>
							Find my shade
						</Link>
						<ProductOneTestimonial isMobile={windowWidth < 750}/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductTestimonial;
