// External
import React from 'react';

// Internal
import Yotpo from '../../../../components/Yotpo';
import { getPrice, getMetafield } from '../../../../utils/helpers';
import isAWholeNumber from '../../../../helpers/isAWholeNumber';

const ProductInfoPrice = ({
	product,
	cart,
	canSubscribe,
	discountPrice,
	activeRadio,
	recommendedProduct,
	isRecommendedSelected,
	rating,
	scrollToReviews,
	discountPercentageWithSub = 0,
	selectedVariant,
}) => {
	const isShowDiscountedPrice =
		canSubscribe && activeRadio === 'auto-delivery';
	const isMiracle = getMetafield(product, 'is_miracle_system');
	const discountText = getMetafield(product, 'discount_text');

	const price = isMiracle
		? product?.variants[0]
		: selectedVariant ||
		  product?.variants.filter(
				(r) => !r.title.toLowerCase().includes('miracle')
		  )[0];

	const priceOfProduct =
			price[isShowDiscountedPrice ? 'price' : 'compareAtPrice'];
	const priceIsWholeNumber = isAWholeNumber(priceOfProduct);

	let oldPrice = priceOfProduct;

	if (isMiracle) {
		if (
			priceOfProduct &&
			typeof priceOfProduct === 'string' &&
			priceOfProduct.split('.')[0]
		) {
			oldPrice = priceOfProduct?.split('.')[0];
		}
	} else {
		if (priceIsWholeNumber) {
			oldPrice = Math.round(priceOfProduct);
		}
		oldPrice = priceOfProduct;
	}

	const isPriceAWholeNumber = isAWholeNumber(price.price);

	const numberOfDecimalToShowOfPrice = isPriceAWholeNumber ? 0 : 2;

	return (
		<>
			<div className='pdp-info__price'>
				{!canSubscribe && (
					<div className='pdp-info__flex'>
						<div
							className={`current-product-price ${
								price?.compareAtPrice || isShowDiscountedPrice
									? 'current-product-price--red'
									: ''
							}`}
						>
							{isShowDiscountedPrice ? (
								`£ ${discountPrice}`
							) : recommendedProduct && isRecommendedSelected ? (
								`£ ${price.price + recommendedProduct.price}`
							) : (
								<>
									{getPrice(
										price?.price,
										cart,
										numberOfDecimalToShowOfPrice
									)}
								</>
							)}
						</div>
						{!!(price?.compareAtPrice || isShowDiscountedPrice) && (
							<div className='original-product-price original-product-price--cross'>
								{price && `£${oldPrice}`}
							</div>
						)}
						{discountText && (
							<div className='pdp-info__discount-text'>
								{discountText}
							</div>
						)}
					</div>
				)}
				{rating && (
					<Yotpo
						rating={rating}
						scrollToReviews={scrollToReviews}
						showCount
					/>
				)}
			</div>
			{!!canSubscribe && discountPercentageWithSub > 0 && (
				<span className='current-product-price__delivery'>
					{`${discountPercentageWithSub}% off every order + free gifts with Subscribe & Save`}
				</span>
			)}
		</>
	);
};

export default ProductInfoPrice;
