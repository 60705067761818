import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import collections from '../../constants/collections';
import './CollectionGrid.scss';

const CollectionGrid = ({ collectionPageV2 = false }) => (
	<div className='collection-grid'>
		{(collections || []).map((collection) => {
			const urlDivided = collection.handle.split('/');
			urlDivided.shift();
			return (
				<Link
					key={collection.handle}
					to={
						collectionPageV2
							? `/${urlDivided[0]}/v2/${urlDivided[1]}`
							: collection.handle
					}
					className='collection-grid__item'
				>
					<div className='collection-grid__img-wrap'>
						<LazyLoadImage
							src={collection.imgSrc}
							alt={collection.title}
							effect='blur'
						/>
					</div>
					<p className='collection-grid__title'>{collection.title}</p>
				</Link>
			);
		})}
	</div>
);

export default CollectionGrid;
