// External
import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import SwiperCore, { Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

// Internal
import SalonHeaderButtons from './SalonHeaderButtons';
import Breadcrumb from '../../../components/BreadCrumb/Index';

import { EXPERTS_SECTION_BREADCRUMB } from '../../../constants/breadcrumbs';

import 'swiper/swiper.scss';
import 'swiper/components/thumbs/thumbs.scss';

const SalonHeader = ({ headerText }) => {
	SwiperCore.use([Autoplay, EffectFade]);

	const swiperStaticImages = useStaticQuery(graphql`
		query {
			salonBackground1: file(relativePath: { eq: "salon/salon-bg.jpg" }) {
				childImageSharp {
					gatsbyImageData(quality: 85)
				}
			}
			salonBackground2: file(relativePath: { eq: "salon/salon-bg-2.jpg" }) {
				childImageSharp {
					gatsbyImageData(quality: 75)
				}
			}
		}
	`);

    const mainSalon = getImage(swiperStaticImages.salonBackground1);
    const secondarySalon = getImage(swiperStaticImages.salonBackground2);

	return (
		<section className='salon-header'>
			<Breadcrumb section={EXPERTS_SECTION_BREADCRUMB} />
			<Swiper
				slidesPerView='1'
				effect='fade'
				autoplay={{ delay: 5000 }}
				className='salon-header--bg'
			>
				<SwiperSlide>
					<GatsbyImage
						image={mainSalon}
						alt='Main salon background'
						loading='eager'
					/>
				</SwiperSlide>
				<SwiperSlide>
					<GatsbyImage
						image={secondarySalon}
						alt='Secondary salon background'
					/>
				</SwiperSlide>
			</Swiper>
			<div className='container'>
				<div className='salon-header__content'>
					<h1 className='salon-header__title'>
						{`${headerText.split(':')[0]}:`}
						<br />
						{headerText.split(':')[1]}
					</h1>
					<span className='salon-header__subtitle'>
						The Times 2020
					</span>
				</div>
				<SalonHeaderButtons />
			</div>
		</section>
	);
};

export default SalonHeader;
