// External
import React, { useContext } from 'react';
import cn from 'classnames';

// Internal
import { StoreContext } from '../../../../../../../context/store-context';
import './ProductMiracleButton.scss';

const ProductMiracleButton = ({
	product,
	oneTime,
	activeSecondLevel,
	recommendedProduct,
	isRecommendedSelected,
	setActiveModal,
	canSubscribe,
	activeRadio,
	selectedValue,
	sellingPlanGroup,
	levelValidator,
	setErrorSelectLevel,
}) => {
	const { addVariantToCart, addMultiVariantsToCart, setNotificationText } =
		useContext(StoreContext);

	const classes = cn('button button--dark pdp-miracle-button', {
		'pdp-miracle-button--full': oneTime,
	});
	const isRecommended = recommendedProduct && isRecommendedSelected;

	const handleSubmit = () => {
		if (typeof levelValidator === 'object') {
			if (!levelValidator.isValidOne) {
				setErrorSelectLevel &&
					setErrorSelectLevel({
						errorLevelOne:
							'Please choose colour before “Add to bag”',
						errorLevelTwo: '',
					});
				return;
			}
			if (!levelValidator.isValidTwo) {
				setErrorSelectLevel &&
					setErrorSelectLevel({
						errorLevelOne: '',
						errorLevelTwo: 'Please choose tone before “Add to bag”',
					});
				return;
			}
		}
		const { storefrontId, title } =
			activeSecondLevel !== 0
				? product.variants.find(
						(el) =>
							el.storefrontId === activeSecondLevel.storefrontId
				  )
				: product;
		const sellingPlanId =
			canSubscribe && activeRadio === 'auto-delivery'
				? sellingPlanGroup.sellingPlans.edges[selectedValue].node.id
				: null;
		const multiplyItems = isRecommended && [
			{
				variantId: storefrontId,
				quantity: 1,
				attributes: [],
				sellingPlanId,
			},
			{
				variantId: recommendedProduct.id,
				quantity: 1,
				attributes: [],
			},
		];

		if (isRecommended) {
			addMultiVariantsToCart(multiplyItems);
		} else if (activeSecondLevel !== 0) {
			addVariantToCart(
				activeSecondLevel.storefrontId,
				1,
				[],
				false,
				product,
				sellingPlanId
			);
		} else {
			addVariantToCart(
				product.variants[0].storefrontId,
				1,
				[],
				false,
				product,
				sellingPlanId
			);
		}
		setNotificationText(`${product.title} - ${title}`);
		setActiveModal && setActiveModal(false);
	};

	return (
		<button
			className={classes}
			style={{
				backgroundColor:
					activeSecondLevel === null ? '#E8E8E8' : '#D56E8F',
			}}
			onClick={handleSubmit}
		>
			<span
				style={{
					color: activeSecondLevel === null ? '#5C5C5C' : 'white',
				}}
			>
				Add to bag
			</span>
		</button>
	);
};

export default ProductMiracleButton;
