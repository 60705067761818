import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './ProductHairBenefits.scss';

const benefits = [
	{
		percentage: '62%',
		title: 'Less breakage',
		description: 'than untreated hair',
		image: '/images/miracle-about/less-breakage.jpg',
	},
	{
		percentage: '38%',
		title: 'Less fade',
		description: 'Over 12 washes vs permanent colour alone',
		image: '/images/miracle-about/less-fade.jpg',
	},
	{
		percentage: '2.7x',
		title: 'Times Stronger',
		description: 'for greater tensile strength',
		image: '/images/miracle-about/times-stronger.jpg',
	},
	{
		percentage: '97%',
		title: 'of people',
		description: 'felt their hair had a professional look',
		image: '/images/miracle-about/of-people-1.png',
	},
	{
		percentage: '96%',
		title: 'of people',
		description: 'felt their hair felt shinier',
		image: '/images/miracle-about/of-people-2.png',
	},
];

const ProductHairBenefits = ({ product }) => (
	<div className='pdp-hair-benefits'>
		<div className='container'>
			<div className='pdp-hair-benefits__content'>
				{benefits.map((benefit) => (
					<div
						key={benefit.percentage}
						className='pdp-hair-benefits__content__benefit'
					>
						<LazyLoadImage
							src={benefit.image}
							alt={benefit.title}
							effect='blur'
						/>
						<div className='pdp-hair-benefits__content__benefit__text'>
							<h3>{benefit.percentage}</h3>
							<h4>{benefit.title}</h4>
							<p>{benefit.description}</p>
						</div>
					</div>
				))}
			</div>
		</div>
	</div>
);

export default ProductHairBenefits;
