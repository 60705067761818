import React from 'react';

import LocalImage from '../../../components/LocalImage';

import './AboutMiracle.scss';

const aboutMiracles = [
	{
		image: 'permanent-colour.png',
		title: 'Kind Permanent Colour',
		desc: '100% grey coverage, no ammonia, and rich, natural-looking colour.',
	},
	{
		image: 'shade-shot.png',
		title: 'Toning Shade Shot',
		desc: 'Glossy toner that creates personalised results to suit your skin and eyes.',
	},
	{
		image: 'miracle-shot.png',
		title: 'Repairing Miracle Shot',
		desc: 'The secret sauce to softness, shine & strength.',
	},
];

const AboutMiracle = ({ windowWidth }) => (
	<div className='about-the-miracle-system-v2'>
		<div className='about-the-miracle-system-v2__text'>
			<h3 className='about-the-miracle-system-v2__title'>
				Unlock the best shade of you with the POWER OF 3:
			</h3>
			<div className='about-the-miracle-system-v2__list'>
				{aboutMiracles.map((item, index) => (
					<div
						key={item.title}
						className='about-the-miracle-system-v2__list__item'
					>
						<div className='about-the-miracle-system-v2__list__item__number'>
							{index + 1}
						</div>
						<LocalImage image={item.image} />
						<p className='about-the-miracle-system-v2__list__item__desc'>
							<strong>{item.title}</strong>
							<br />
							{item.desc}
						</p>
					</div>
				))}
			</div>
		</div>
	</div>
);

export default AboutMiracle;
