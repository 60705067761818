import { graphql, useStaticQuery } from 'gatsby';

const useProductByHandle = (handle, isArray = false) => {
	const { allShopifyProduct } = useStaticQuery(graphql`
			{
				allShopifyProduct {
					edges {
						node {
							...ProductCard
						}
					}
				}
			}
		`),
		product = !Array.isArray(handle)
			? allShopifyProduct.edges.find(({ node }) => node.handle === handle)
			: allShopifyProduct.edges.find(({ node }) =>
					handle.includes(node.handle)
			  );
	return !Array.isArray(handle) ? [product] : product;
};

export default useProductByHandle;
