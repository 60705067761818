import React, { useContext, useEffect } from 'react';
import SwiperCore, { Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import ModalUpsellProduct from './ModalUpsellProduct';

import { getPrice, goToCheckout } from '../../../utils/helpers';
import { useWindowWidth } from '../../../utils/hooks';
import useProductByHandle from '../../../utils/useProductByHandle';

import { StoreContext } from '../../../context/store-context';
import 'swiper/components/scrollbar/scrollbar.scss';
import './ModalUpsell.scss';

SwiperCore.use([Scrollbar]);

const ModalUpsell = ({ modal }) => {
	const allProducts = useProductByHandle(modal.product, false),
		{ title, text, subtext, image } = modal.info,
		width = useWindowWidth(),
		{
			cart,
			showModal,
			setNotificationText,
			addVariantToCart,
			setUpsellModalWasOpened,
		} = useContext(StoreContext);

	useEffect(() => {
		setUpsellModalWasOpened(true);
	}, []);

	const handleClick = (id, title, product) => {
		const note = {
			key: 'note_added_modal_upsell',
			value: 'Added item from preCheckoutModal',
		};
		addVariantToCart(id, 1, [], true, product, null, false, note)
			.then(() => {
				setNotificationText(title);
				showModal(false);
			})
			.then(() => goToCheckout(cart));
	};

	return (
		<div className='modal-upsell'>
			<div className='modal-upsell__header'>
				<h3 className='modal-upsell__title'>{title}</h3>
				{text && <p className='modal-upsell__text'>{text}</p>}
			</div>
			<div
				className={`modal-upsell__products ${
					(allProducts || []).length < 3 &&
					'modal-upsell__products--center'
				}`}
			>
				{(allProducts || []).length > 2 && (
					<Swiper
						slidesPerView={width > 768 ? 3 : 2}
						spaceBetween={width > 768 ? 55 : 10}
						watchSlidesVisibility
						watchSlidesProgress
						simulateTouch
						scrollbar={{ draggable: true }}
					>
						{(allProducts || []).map(({ node: product }) => (
							<SwiperSlide key={`modalUpsell${product.id}`}>
								<ModalUpsellProduct
									product={product}
									text={subtext}
									image={image}
									handleClick={handleClick}
								/>
							</SwiperSlide>
						))}
					</Swiper>
				)}
				{(allProducts || []).length < 3 &&
					(allProducts || []).map(({ node: product }) => {
						const price = getPrice(
							product.priceRangeV2.minVariantPrice.amount,
							cart,
							2
						);
						return (
							<ModalUpsellProduct
								key={`modalUpsell${product.id}`}
								product={product}
								text={subtext}
								image={image}
								price={price}
								handleClick={handleClick}
							/>
						);
					})}
			</div>
			<div className='modal-upsell__footer modal-upsell-footer'>
				<button
					type='button'
					className='modal-upsell-footer__continue'
					onClick={() => goToCheckout(cart)}
				>
					<span>No thanks, go to checkout</span>
				</button>
			</div>
		</div>
	);
};

export default ModalUpsell;
