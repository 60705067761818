import React from 'react';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
	createArray,
	decodeBase64,
	getMetafield,
} from '../../../../utils/helpers';
import useProductByHandle from '../../../../utils/useProductByHandle';

const ProductsItem = ({ handle, setProductData }) => {
	const product = useProductByHandle(handle),
		advantages = product ? createArray(getMetafield(product, 'list')) : [],
		openProductModal = () => {
			if (product) {
				setProductData({
					id: decodeBase64(product.storefrontId),
					productType: product.productType,
					title: product.title,
					handle: product.handle,
					description: product.descriptionHtml,
					advantages,
					price: parseInt(
						product.priceRangeV2.minVariantPrice.amount
					).toFixed(0),
					image:
						product.images[0].localFile &&
						product.images[0].localFile.childImageSharp
							? product.images[0].localFile.childImageSharp
									.gatsbyImageData
							: product.images[0].originalSrc,
				});
			}
		};
	return (
		<div className='shop-look-item'>
			{product && (
				<>
					<div className='shop-look-item__image'>
						{product.images &&
							product.images.length &&
							(product.images[0].gatsbyImageData ? (
								<GatsbyImage
									alt={product.title}
									image={getImage(
										product.images[0].gatsbyImageData
									)}
								/>
							) : (
								<GatsbyImage
									src={getImage(
										product.images[0].originalSrc
									)}
									alt={product.title}
								/>
							))}
						<button
							className='shop-look-item__link'
							onClick={openProductModal}
						/>
					</div>
					<div className='shop-look-item__content'>
						<h3>{product.title}</h3>
					</div>
				</>
			)}
		</div>
	);
};

export default ProductsItem;
