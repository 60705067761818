// External
import React, { useEffect } from 'react';

// Internal
import { useWindowWidth } from '../../utils/hooks';
import getVariantPrice from '../../helpers/productsPricing';

import './DeliveryRadio.scss';

const DeliveryRadio = ({
	activeRadio,
	setActiveRadio,
	radios,
	center,
	classname,
	discountPercentageWithSub = 15,
	product,
	levelValidator,
	setErrorSelectLevel,
}) => {
	const radiosList = radios || [
		{
			text: `<b>Subscribe & Save ${discountPercentageWithSub}%</b><br/> (Free shipping + free gifts)* <br/> <p>${getVariantPrice(
				product,
				true,
				discountPercentageWithSub
			)}</p>`,
			val: 'auto-delivery',
		},
		{
			text: `<b>One-Time Purchase</b><br/> (Shipping fees apply) <br/> <p>${getVariantPrice(
				product
			)}</p>`,
			val: 'One-Time Purchase',
		},
	];

	const isValid =
		typeof levelValidator === 'object' &&
		levelValidator?.isValidOne &&
		levelValidator?.isValidTwo;

	const getActive = (value) => {
		if (typeof levelValidator !== 'object') return value;
		if (isValid) return value;
	};

	const isAutoDelivery = activeRadio === 'auto-delivery';

	const width = useWindowWidth();

	useEffect(() => {
		const setStylePositions = () => {
			const radioPosition = isAutoDelivery ? '3' : '2';
			const lastRadio = document.querySelector(
				`.delivery-radios__item:nth-child(${radioPosition})`
			);
			const unsubscribe = document.querySelector(
				'.delivery-radios__unsubscribe'
			);
			if (!lastRadio || !levelValidator) return;
			if (width > 550) {
				if (unsubscribe) unsubscribe.style.bottom = '-25px';
				lastRadio.style.marginTop = '0px';
				return;
			}
			if (
				levelValidator.isValidOne &&
				levelValidator.isValidTwo &&
				isAutoDelivery
			) {
				lastRadio.style.marginTop = '100px';
				if (unsubscribe) unsubscribe.style.bottom = 'calc(50% + 25px)';
			} else {
				lastRadio.style.marginTop = '25px';
				if (unsubscribe) unsubscribe.style.bottom = 'calc(50% - 10px)';
			}
		};
		setStylePositions();
	}, [levelValidator, activeRadio]);

	return (
		<div
			className={`delivery-radios ${
				center && 'delivery-radios--center'
			} ${classname && classname}`}
		>
			{isAutoDelivery && (
				<span className='delivery-radios__deliver'>Deliver every:</span>
			)}
			{radiosList.map((radio) => {
				const val = radio.val.toLowerCase().replace(' ', '-');
				return (
					<label
						key={radio.val}
						className={`delivery-radios__item ${
							classname && `${classname}__item`
						}`}
						style={{
							border:
								activeRadio === getActive(val)
									? `1px solid pink`
									: '1px solid transparent',
						}}
					>
						<div
							onClick={() => {
								if (typeof levelValidator === 'object') {
									if (!levelValidator?.isValidOne) {
										setErrorSelectLevel &&
											setErrorSelectLevel({
												errorLevelOne:
													'Please select tone before Selecting an option',
												errorLevelTwo: '',
											});
									} else if (!levelValidator?.isValidTwo) {
										setErrorSelectLevel &&
											setErrorSelectLevel({
												errorLevelOne: '',
												errorLevelTwo:
													'Please select tone before Selecting an option',
											});
									}
								}
							}}
						>
							<input
								type='radio'
								name='purchase_type'
								value={val}
								checked={activeRadio === getActive(val)}
								onChange={() => {
									if (typeof levelValidator !== 'object') {
										setActiveRadio(val);
									} else if (isValid) {
										setActiveRadio(val);
									}
								}}
							/>
							<span
								className={`delivery-radios__text ${
									classname && `${classname}__text`
								}`}
								dangerouslySetInnerHTML={{ __html: radio.text }}
							/>
						</div>
					</label>
				);
			})}
			{isAutoDelivery && (
				<span className='delivery-radios__unsubscribe'>
					* Cancel or pause anytime
				</span>
			)}
		</div>
	);
};

export default DeliveryRadio;
