import React from 'react';

import SalonInfoContacts from './SalonInfoContacts/SalonInfoContacts';

const SalonInfo = ({ allHours }) => (
	<section className='salon-info'>
		<div className='container'>
			<div className='salon-info__content'>
				<SalonInfoContacts isToMap classNames='salon-info__contacts' allHours={allHours} />
				<div className='salon-info__text'>
					<p>
						Nestled in a mews house in London’s Holland Park, the
						Josh Wood Colour Atelier is home to Josh and his
						handpicked, dream team of colourists and stylists, famed
						for their ability to create personalised colour,
						tailored for the individual.
					</p>
				</div>
			</div>
		</div>
	</section>
);
export default SalonInfo;
