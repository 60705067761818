import React, { useContext, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import cn from 'classnames';

import { StoreContext } from '../../../../context/store-context';
import { useWindowWidth } from '../../../../utils/hooks';
import LocalImage from '../../../../components/LocalImage';

const ProductPhotos = ({
	product,
	thumbsSwiper,
	customPreviewImage,
	setSliderNoAnimation,
}) => {
	const { variantSelected, setSlideProperties, setModal, showModal } =
		useContext(StoreContext);
	const windowWidth = useWindowWidth();
	const [swiper, setSwiper] = useState(null);

	const imagesUrls =
		(product?.media || []).length &&
		product?.media.map((item, index) => ({
			url: item.shopifyId,
			originalSrc: item.preview.image.originalSrc,
			index,
		}));

	const handleClick = (index) => {
		if (windowWidth < 750) {
			setModal(null, 'zoom-images-mobile', null, {
				product,
				customPreviewImage,
				index,
			});
			showModal(true);
		}
	};

	useEffect(() => {
		let currentSlide = null;

		if (
			imagesUrls &&
			imagesUrls.length &&
			swiper &&
			variantSelected &&
			variantSelected?.image
		) {
			const variantTitle = variantSelected.title;
			const transformedVariantTitle =
				typeof variantTitle === 'string' &&
				variantTitle.toLowerCase().replaceAll(' ', '-');

			currentSlide = imagesUrls.filter((item) =>
				item.originalSrc.includes(transformedVariantTitle)
			)[0];

			!currentSlide
				? (currentSlide = imagesUrls.filter(
						(item) =>
							item.originalSrc &&
							item.originalSrc.includes(variantSelected.image.originalSrc)
				  )[0])
				: currentSlide;

			setSliderNoAnimation(true);

			currentSlide &&
				Number.isInteger(currentSlide?.index) &&
				swiper.slideTo(currentSlide.index);

			setTimeout(() => {
				setSliderNoAnimation(false);
			}, 100);
		}
	}, [variantSelected, setSlideProperties, swiper]);

	return (
		<div className='pdp-images-v2__gallery__images'>
			{Array.isArray(product?.media) && product.media.length ? (
				<Swiper
					onSwiper={setSwiper}
					slidesPerView={windowWidth > 749 ? 1 : 1.5}
					simulateTouch='false'
					centeredSlides='false'
					spaceBetween={10}
					className='gallery-top'
					thumbs={{ swiper: thumbsSwiper }}
					breakpoints={{
						750: { spaceBetween: 0 },
					}}
					preloadImages={false}
					lazy
				>
					{Array.isArray(product?.media) &&
						product.media.map((image, index) => (
							<SwiperSlide
								className={cn('pdp-v2-photo-slider__item', {
									'pdp-v2-photo-slider__item--center':
										(product?.images || []).length === 1,
								})}
								key={image.shopifyId}
								onClick={
									windowWidth < 750
										? () => handleClick(index)
										: null
								}
							>
								<div
									id='pdpPhotoSliderImg'
									className='pdp-v2-photo-slider__img'
								>
									{image.preview.image.gatsbyImageData &&
									!image?.preview?.image?.originalSrc?.includes(
										'.gif'
									) ? (
										<GatsbyImage
											className='swiper-lazy'
											loading={
												index > 0 ? 'lazy' : 'eager'
											}
											alt={image.shopifyId}
											image={
												image.preview.image
													.gatsbyImageData
											}
										/>
									) : (
										<GatsbyImage
											className='swiper-lazy'
											loading={
												index > 0 ? 'lazy' : 'eager'
											}
											image={
												image.preview.image
													.gatsbyImageData
											}
											alt={image.shopifyId}
										/>
									)}
								</div>
							</SwiperSlide>
						))}
				</Swiper>
			) : (
				<LocalImage image='no-image.jpg' alt={product?.title} />
			)}
		</div>
	);
};

export default ProductPhotos;
