import React, { useContext, useEffect } from 'react';
import SEO from '../../../components/SEO';
import './style.scss';
import { StoreContext } from '../../../context/store-context';
import useAskFlowAddToCartButtons from '../../../hooks/useAskFlowAddToCartButtons';
import DangerouslySetHtmlContent from '../../../components/DangerouslySetHtmlContent';

const ZoovuPage = ({ location, pageContext: { page } }) => {
	const { addVariantToCart, addMultiVariantsToCart } =
		useContext(StoreContext);

	useAskFlowAddToCartButtons(addVariantToCart, addMultiVariantsToCart);

	useEffect(() => {
		const observer = new MutationObserver(() => {
			const injectedDivs = document.querySelectorAll('body > div');
			for (const injectedDiv of injectedDivs) {
				setTimeout(() => {
					const isPopupForm = injectedDiv.querySelector(
						"[data-testid='klaviyo-form-TWAeX4']"
					);
					if (isPopupForm) {
						injectedDiv.remove();
					}
				}, 10);
			}
		});
		observer.observe(document.body, { childList: true, subtree: true });

		return () => {
			observer.disconnect();
		};
	}, []);

	const scriptContent = `
	<script
		id='askflow-frame-loader'
		defer
		src='https://quiz.askflow.ai/askflow-frame.min.js?quizId=5d82eda5-52a6-48e7-8d37-efb7d7169bf7&width=100%25&height=700px&headlessMode=true'
	></script>;
  `;

	return (
		<>
			<SEO page={page} location={location} />
			<div className='consultation-container'>
				<DangerouslySetHtmlContent html={scriptContent} />
			</div>
		</>
	);
};

export default ZoovuPage;
