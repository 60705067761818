import React, { useState } from 'react';
import cn from 'classnames';

import { useWindowWidth } from '../../utils/hooks';

import './Video.scss';

const Video = ({ classname, title, videoId, initialLoad = false }) => {
	const windowWidth = useWindowWidth();
	const [loadedVideo, setLoadedVideo] = useState(initialLoad);

	return (
		<div
			onMouseEnter={
				windowWidth > 991 ? () => setLoadedVideo(true) : () => false
			}
			onClick={
				windowWidth < 992 ? () => setLoadedVideo(true) : () => false
			}
			className={`video ${classname && classname}`}
		>
			{title ? <h3 className='video-title'>{title}</h3> : null}

			{loadedVideo && (
				/* eslint-disable-next-line */
				<iframe
					className={cn('video__iframe', {
						'video__iframe--loaded': loadedVideo,
					})}
					width='100%'
					height='400px'
					src={`https://www.youtube.com/embed/
                    ${videoId}?autoplay=0&showinfo=1`.replace(/\s+/g, '')}
					frameBorder='0'
					allowFullScreen
				/>
			)}
			<div
				className='_2hdkJ'
				style={{
					backgroundImage: `url(https://img.youtube.com/vi/
                    ${videoId}/sddefault.jpg)`,
				}}
			>
				<div className='_16Q0f' />
			</div>
		</div>
	);
};

export default Video;
