// External
import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Internal
import './ExpertsGridItem.scss';

const ExpertsGridItem = ({ expert }) => (
	<li className='expert-item'>
		<Link to={`/pages/meet-the-experts/${expert.handle}`}>
			<div className='expert-item__img'>
				{expert.img_url ? (
					<LazyLoadImage
						src={expert.img_url}
						alt={expert.name}
						effect='blur'
						wrapperClassName='lazyLoad-wrapper-img'
					/>
				) : (
					<LazyLoadImage
						src='/images/empty-expert.png'
						alt='empty'
						effect='blur'
						wrapperClassName='lazyLoad-wrapper-img'
					/>
				)}
			</div>
			<h3 className='expert-item__name'>{expert.name}</h3>
			<span className='expert-item__profession'>{expert.tags[0]}</span>
		</Link>
	</li>
);
export default ExpertsGridItem;
