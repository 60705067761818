import React from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

import LocalImage from '../LocalImage';
import { useWindowWidth } from '../../utils/hooks';

import './AsSeenIn.scss';

SwiperCore.use([Autoplay]);

const AsSeenInV2 = () => {
	const windowWidth = useWindowWidth();
	const isMobile = windowWidth < 750;
	return (
		<section className='as-seen-in-v2'>
			<h2 className='as-seen-in-v2__title'>As seen in...</h2>
			<Swiper
				loop
				autoplay={{
					delay: isMobile ? 5000 : 2500,
					disableOnInteraction: false,
				}}
				slidesPerView={isMobile ? 1 : 2}
			>
				<SwiperSlide>
					<LocalImage image='pdp/partner-1.webp' alt='Partner 1' />
				</SwiperSlide>
				<SwiperSlide>
					<LocalImage image='pdp/partner-2.webp' alt='Partner 2' />
				</SwiperSlide>
			</Swiper>
		</section>
	);
};

export default AsSeenInV2;
